















































import { Component, Prop, Vue } from 'vue-property-decorator'

import CompareSession from '@/components/data/weatherstation/CompareSessions/CompareSessions.vue'
import Overview from '@/components/data/weatherstation/Overview/Overview.vue'
import Track from '@/components/data/common/Track/Track.vue'

import { DefaultSettingCompareSession } from '@/components/data/weatherstation/CompareSessions/definitions'
import { vrsStore } from '@/store'
import { StateApp } from '@/store/app/definitions'
import { StateEvent } from '@/store/event/definitions'
import { selectionSessions } from '@/views/ClimateRecap/helper'

@Component({
  components: {
    'compare-sessions': CompareSession,
    overview: Overview,
    'draw-track': Track
  }
})

export default class ClimateRecap extends Vue {
  stateEvent: StateEvent = vrsStore.state.event
  stateApp: StateApp = vrsStore.state.app

  seriesSessions = ['avgAirTemp', 'avgTrackTemp', 'sumRainIntensity', 'avgWindSpeed', 'avgWindGust', 'mainWindDirection', 'avgHumidity', 'avgPressure']
  seriesOverview = ['avgAirTemp', 'avgTrackTemp', 'sumRainIntensity', 'avgWindSpeed', 'avgHumidity', 'avgPressure']

  @Prop(Number) readonly eventId: number | undefined

  get defaultSelected (): DefaultSettingCompareSession {
    const defaultData = {
      defaultSessionA: null,
      defaultSessionB: null,
      defaultStationA: 'all',
      defaultStationB: 'all'
    }
    return selectionSessions(defaultData, this.stateEvent.sessionsWithStat)
  }
}
